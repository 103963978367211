import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
const locale = localStorage.getItem("locale") || "en";

const i18n = new VueI18n({
  locale,
  fallbackLocale: "nl",
  messages: {
    en: {
      message: {
        hello: "hello world",
        greeting: "good morning",
        login_success: "Hi {email}, you are successfully logged in as {role}",
        login_error:"Unable to log in with provided credentials.",
        saved_error: "Something went wrong",
      },
    },
    nl: {
      message: {
        hello: "こんにちは、世界",
        greeting: "おはようございます",
        login_success: "Hoi {email}, je bent succesvol ingelogd als {role}",
        login_error:"Kan niet inloggen met de opgegeven inloggegevens.",
        saved_error: "Er is iets misgegaan",
      },
    },
  },
});

export default i18n;
